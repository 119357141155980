import { Section, FieldBuilder } from "@components/ProjectForm";
import { SectionProvider } from "@contexts";

export const FormSection = ({ schema, handleSubmit }) => {
    return (
        <>
            {schema?.map((element) => {
                switch (element.type) {
                    case 'SECTION':
                    case 'SEMICUSTOM':
                        return (
                            <SectionProvider key={element.id} showCollapsed={element.showCollapsed}>
                                <Section section={element}>
                                    <FieldBuilder handleSubmit={handleSubmit} fields={element.fields} />
                                </Section>
                            </SectionProvider>
                        )
                    default: {
                        return null
                    }
                }
            })}

        </>
    )
}

export default FormSection;

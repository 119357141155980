import React from 'react';
import { useMsal } from '@azure/msal-react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { useRules } from '@hooks/useRules';
import { FIELD_TYPES } from "@ais/constants";
import { UnderstandingOfEntityProvider } from "@ais/contexts";
import {
  Checkbox,
  CLADatePicker,
  DropDown,
  Formula,
  FreeText,
  LongAnswer,
  QuestionGroup,
  RadioButtonList,
  ShortAnswer,
  Table
} from '@ais/forms';

import { UOEAuditAreaTable } from '@components/CustomForm';
import { FieldBuilder, Link } from '@components/ProjectForm';
import { useProjectFormContext } from "@contexts";
import FieldWrapper from '@components/Common/FieldWrapper/FieldWrapper';
import { useUpdateMyPresence } from '@providers/concurrency/RoomProvider';
import { useOthers } from "@providers/concurrency/store/users";
import { getQuestionId } from '@utilities/helpers';

const StyledColumn = styled('div')(({ width }) => ({
  display: 'flex',
  position: 'relative',
  padding: '24px',
  boxSizing: 'border-box',
  width: `${width}%`
}));

const StyledFieldWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '10px 0',
  width: '100%',
  padding: '8px'
});

export const FieldV2 = (props) => {
  const { field: { editableByClient, id, linkType, type, width, rules }, handleSubmit } = props;
  const { answers, actions: { onBlur, onFocus } } = useProjectFormContext();
  const others = useOthers();
  const { accounts } = useMsal()
  const userId = accounts[0].localAccountId.toLowerCase();
  const focusedField = others.find(
    (user) => user.presence.focusedId === id && user.info.userId != userId
  )
  let questionId = getQuestionId(id);
  const lockingUser = focusedField ? { userId: focusedField?.info?.userId, alternativeName: focusedField?.info?.name } : undefined;
  const disabled = linkType === 'Read Only' || !editableByClient || lockingUser !== undefined ||
    others.find(user => user.presence.focusedId === questionId && user.info.userId.toLowerCase() !== userId.toLowerCase()) !== undefined;
  const updateMyPresence = useUpdateMyPresence();
  const lockField = () => { updateMyPresence({ focusedId: questionId }) }
  const unlockField = () => {
    onBlur();
    updateMyPresence({ focusedId: null });
  }
  const { shouldDisplay } = useRules(rules);
  if (!shouldDisplay) return <></>;

  const renderLink = () => {
    return <Link field={props.field} />;
  }

  const renderFields = () => {
    switch (type) {
      case FIELD_TYPES.FREETEXT:
      case FIELD_TYPES.FREETEXT_NO_RULES:
        return <FreeText {...props} />;
      case FIELD_TYPES.SHORT_ANSWER:
        return (
          <ShortAnswer
            {...props}
            disabled={disabled}
            onSubmit={handleSubmit}
            lockField={lockField}
            unlockField={unlockField}
            onFocus={onFocus}
            renderLink={renderLink}
          />
        );
      case FIELD_TYPES.LONG_ANSWER:
        return (
          <LongAnswer
            {...props}
            disabled={disabled}
            onSubmit={handleSubmit}
            lockField={lockField}
            unlockField={unlockField}
            onFocus={onFocus}
            renderLink={renderLink}
          />
        );
      case FIELD_TYPES.RADIOGROUP:
        return (
          <RadioButtonList
            {...props}
            disabled={disabled}
            onSubmit={handleSubmit}
            lockField={lockField}
            unlockField={unlockField}
            onFocus={onFocus}
            renderLink={renderLink}
          />
        );
      case FIELD_TYPES.DROPDOWN:
        return (
          <DropDown
            {...props}
            disabled={disabled}
            answerList={answers}
            lockField={lockField}
            unlockField={unlockField}
            onSubmit={handleSubmit}
            onFocus={onFocus}
            renderLink={renderLink}
          />
        );
      case FIELD_TYPES.CHECKBOXGROUP:
        return (
          <Checkbox
            {...props}
            disabled={disabled}
            onSubmit={handleSubmit}
            lockField={lockField}
            unlockField={unlockField}
            onFocus={onFocus}
            renderLink={renderLink}
          />
        );
      case FIELD_TYPES.DATE_PICKER:
        return (
          <CLADatePicker
            {...props}
            disabled={disabled}
            onSubmit={handleSubmit}
            lockField={lockField}
            unlockField={unlockField}
            onFocus={onFocus}
            renderLink={renderLink}
          />
        );
      case FIELD_TYPES.FORMULA:
        return (
          <Formula
            {...props}
            disabled={disabled}
            answerList={answers}
            onFocus={onFocus}
          />
        );
      case FIELD_TYPES.TABLE:
        return (
          <Table
            {...props}
            disabled={disabled}
            onSubmit={handleSubmit}
            lockField={lockField}
            unlockField={unlockField}
            onFocus={onFocus}
          />
        );
      case FIELD_TYPES.QUESTION_GROUP:
        return (
          <QuestionGroup
            {...props}
            disabled={disabled}
            onSubmit={handleSubmit}
            lockField={lockField}
            unlockField={unlockField}
            fieldWrapper={FieldBuilder}
            onFocus={onFocus}
          />
        );
      case FIELD_TYPES.UNDERSTANDING_OF_ENTITY: {
        return (
          <UnderstandingOfEntityProvider>
            <UOEAuditAreaTable />
          </UnderstandingOfEntityProvider>
        );
      }
      default:
        return <></>;
    }
  };

  return (
    <StyledColumn width={width}>
      <StyledFieldWrapper>
        <Box sx={{ width: '100%' }}>
          <FieldWrapper lockingUser={lockingUser} isLocked={!!focusedField}>
            {renderFields()}
          </FieldWrapper>
        </Box>
      </StyledFieldWrapper>
    </StyledColumn>
  );
};

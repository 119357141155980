import React, { useState, useMemo } from 'react';
import { styled } from '@mui/system';

import { LinkIconComponent } from '@ais/assets';
import { QUESTION_LINK_TYPE } from '@ais/constants'

import { useProjectFormContext } from '@contexts';

import { LinkPanel } from '../LinkPanel';

const StyledDiv = styled('div')({
	cursor: 'pointer',
	margin: '5px 0 0 5px',
	alignSelf: 'center'
});

export const Link = ({ field }) => {
	const [linkedFieldsShown, setLinkedFieldsShown] = useState(false);
	const { linkedQuestions } = useProjectFormContext();
	const { QUESTION_LINK_TYPE: { DUPLICATE_QUESTION } } = QUESTION_LINK_TYPE;

	const hasLinkedQuestions = useMemo(() => {
		const fieldIsLinked = linkedQuestions?.includes(field.id) || (linkedQuestions?.includes(field?.existingQuestionId) && field?.linkType !== DUPLICATE_QUESTION);
		return fieldIsLinked;
	}, [linkedQuestions]);

	const handleClose = () => {
		setLinkedFieldsShown(false);
	};

	if (!hasLinkedQuestions) return <></>;

	return (
		<StyledDiv>
			<LinkIconComponent onClick={() => setLinkedFieldsShown((prev) => !prev)} />
			{linkedFieldsShown && <LinkPanel open={linkedFieldsShown} onClose={handleClose} questionId={field?.existingQuestionId ? field.existingQuestionId : field.id} disabled />}
		</StyledDiv>
	);
};

import { createContext, useContext, useState } from "react";

const SectionContext = createContext()

export const SectionProvider = ({ showCollapsed, children }) => {
  const [expanded, setExpanded] = useState(!showCollapsed);

  const handleExpanded = () => setExpanded((oldState) => !oldState);

  return (
    <SectionContext.Provider value={{
      expanded,
      actions: {
        onSectionClicked: handleExpanded
      }
    }}>
      {children}
    </SectionContext.Provider>
  )
}

export const useSectionContext = () => {
  const ctx = useContext(SectionContext)
  if (!ctx) {
    throw new Error("`SectionContext` must be used within SectionProvider")
  }
  return ctx
}
